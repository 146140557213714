.hover-bold:hover{font-weight: bold;}
.hover-bigger2:hover{font-size: 2.0em}
.hover-bigger1:hover{font-size: 2.2em}
.hover-bigger:hover{font-size: 1.5em}
.hover-mouse:hover{cursor: pointer}
/* !layout */
.valign{vertical-align: middle;}
.lh-20{line-height:2rem}
.lh-30{line-height:3rem}

.mh-100{min-height: 100vh}

.bg-darkRed{background-color: #D2373F}
.bg-darkBlue{background-color: #0B4875}
.bigger1{font-size: 2.2em}
.bigger{font-size: 1.5em}
.c-darkRed{color: #D2373F}
.c-darkBlue{color: #0B4875}

.fs-20{font-size: 24px}